import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { login } from '.';

const getAuth = gql`
  query {
    auth @client {
      is_authenticated
    }
  }
`;

const PrivateRoute = ({ location, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Query query={getAuth}>
        {({ loading, data }) => {
          if (loading) return null;
          if (data.auth.is_authenticated) return <Component {...props} />;
          login(location);
          return null;
        }}
      </Query>
    )}
  />
);

export default withRouter(PrivateRoute);
