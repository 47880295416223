import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Segment, Table } from 'semantic-ui-react';
import Elasticsearch from '../components/Elasticsearch';
import PlaceholderRow from './PlaceholderRow';
import './TaskQueue.css';

const getProfileQuery = gql`
  query Profile {
    profile {
      id
      default_office {
        id
        name
      }
      service_area {
        id
        name
        service_area_group {
          id
          name
        }
      }
      roles {
        id
        assignable
      }
    }
  }
`;

const placeholderTable = (
  <Segment className="TaskQueue" textAlign="left">
    <span>Task Queue</span>
    <Table basic="very" size="small">
      <Table.Body>
        <PlaceholderRow />
        <PlaceholderRow />
        <PlaceholderRow />
      </Table.Body>
    </Table>
  </Segment>
);

const TaskQueue = () => (
  <Query query={getProfileQuery}>
    {({ loading, data: { profile } }) => {
      if (loading) return placeholderTable;
      const assignableRoles = profile.roles.filter(role => role.assignable);
      const assignedToMyRole = assignableRoles.map(role => ({ term: { assignee_id: role.id } }));
      const inMyOffice = (profile.default_office && profile.default_office.id)
        && { term: { 'patient.office_id': profile.default_office.id } };
      const inMyServiceArea = { term: { 'patient.service_area_id': profile.service_area.id } };
      const inMyServiceAreaGroup = {
        term:
        { 'patient.service_area_group_id': profile.service_area.service_area_group.id },
      };
      const isNotification = { term: { type: 'Notification' } };

      const aggregations_buckets = {
        service_area_count: {
          filter: {
            bool: {
              must: [
                inMyServiceArea,
                {
                  bool: {
                    should:
                      assignedToMyRole,
                  },
                },
              ],
              must_not: [isNotification],
            },
          },
        },
        service_area_group_count: {
          filter: {
            bool: {
              must: [
                inMyServiceAreaGroup,
                {
                  bool: {
                    should:
                      assignedToMyRole,
                  },
                },
              ],
              must_not: [isNotification],
            },
          },
        },
      };

      if (profile.default_office) {
        aggregations_buckets.office_count = {
          filter: {
            bool: {
              must: [
                inMyOffice,
                {
                  bool: {
                    should:
                      assignedToMyRole,
                  },
                },
              ],
              must_not: [isNotification],
            },
          },
        };
      }

      const aggregationsQuery = {
        index: `${process.env.REACT_APP_ELASTICSEARCH_INDEX_PREFIX || ''}todos`,
        body: {
          query: { match_all: {} },
          aggs: {
            global: {
              global: {},
              aggs: aggregations_buckets,
            },
          },
        },
      };

      return (
        <Elasticsearch
          query={aggregationsQuery}
          mode="search"
          unwrap={false}
          allowSimultaneousQueries={false}
        >
          {({ aggregations }) => (
            <Segment className="TaskQueue" textAlign="left">
              <span>Task Queue</span>
              <Table basic="very" size="small">
                <Table.Body>
                  {!aggregations && <PlaceholderRow />}
                  {(aggregations && profile.default_office) && (
                    <Table.Row>
                      {profile.default_office && (
                        <>
                          <Table.Cell>
                            {profile.default_office.name}
                          </Table.Cell>
                          <Table.Cell>
                            {aggregations.global.office_count.doc_count.toLocaleString()}
                          </Table.Cell>
                        </>
                      )}
                    </Table.Row>
                  )}
                  {!aggregations && <PlaceholderRow />}
                  {(aggregations) && (
                    <Table.Row>
                      <Table.Cell>{profile.service_area.name}</Table.Cell>
                      <Table.Cell>
                        {aggregations.global.service_area_count.doc_count.toLocaleString()}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {!aggregations && <PlaceholderRow />}
                  {(aggregations) && (
                    <Table.Row>
                      <Table.Cell>
                        {profile.service_area.service_area_group.name}
                      </Table.Cell>
                      <Table.Cell>
                        {aggregations.global.service_area_group_count.doc_count.toLocaleString()}
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </Segment>
          )}
        </Elasticsearch>
      );
    }}
  </Query>
);


export default TaskQueue;
