import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Icon } from 'semantic-ui-react';

class ShowHide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: !!props.active,
    };
  }

  handleClick = () => {
    this.setState(prevState => ({ active: !prevState.active }));
  }

  render() {
    return (
      <Accordion className="ShowHide" fluid>
        <Accordion.Title active={this.state.active} onClick={this.handleClick}>
          <Icon name="dropdown" />
          {this.props.title}
        </Accordion.Title>
        <Accordion.Content active={this.state.active}>
          { this.props.children }
        </Accordion.Content>
      </Accordion>
    );
  }
}

ShowHide.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ShowHide;
