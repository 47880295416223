import React, { Component } from 'react';
import { Menu, Label, Icon } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faClock, faArrowCircleUp } from '@fortawesome/pro-light-svg-icons';
import './TasksNavigation.css';
import InternalUserSearch from './InternalUserSearch';

class TasksNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userSearchActive: false,
    };
  }

  toggleSearch = () => {
    this.setState(prevState => ({
      userSearchActive: !prevState.userSearchActive,
    }));
  }

  render() {
    const { aggregations, buckets, onViewAnotherQueue } = { ...this.props };
    return (
      <div className="TasksNavigation">
        <Menu secondary vertical size="small">
          <Menu.Item as={NavLink} to="/tasks/mine" data-cy="tasks-assigned-to-me">
            <FontAwesomeIcon icon={faUserCircle} />
            Assigned to Me
            { aggregations && (
              <Label color="orange">{aggregations.global.mine.doc_count}</Label>
            )}
          </Menu.Item>
          <Menu.Item as={NavLink} to="/tasks/waiting" data-cy="tasks-waiting">
            <FontAwesomeIcon icon={faClock} />
            Waiting
            { aggregations && (
              <Label color="orange">{aggregations.global.waiting.doc_count}</Label>
            )}
          </Menu.Item>
          { buckets.suggested && (
            <Menu.Item as={NavLink} to="/tasks/suggested" data-cy="tasks-suggested">
              <FontAwesomeIcon icon={faArrowCircleUp} />
              Suggested
            </Menu.Item>
          )}
        </Menu>
        <hr />
        <Menu secondary vertical size="small">
          { buckets.all && (
            <Menu.Item as={NavLink} to="/tasks/all" data-cy="all-open-tasks">
              All Open Tasks
            </Menu.Item>
          )}
          <Menu.Item as={NavLink} to="/tasks/notifications" position="right" data-cy="tasks-notifications">
            {
              aggregations && aggregations.global.notifications.doc_count > 0 ? (
                <>
                  Notifications
                  <Icon name="bell" color="orange" />
                </>
              ) : (
                <>
                  Notifications
                  <Icon name="bell" color="grey" />
                </>
              )
            }
          </Menu.Item>
          <div className={this.state.userSearchActive ? 'hide' : null}>
            <Menu.Item onClick={() => this.toggleSearch()} data-cy="tasks-assigned-to-someone-else">
              Assigned to Someone Else
            </Menu.Item>
          </div>
          <div className={this.state.userSearchActive ? null : 'hide'}>
            <InternalUserSearch
              onResultSelect={(result) => {
                this.toggleSearch();
                onViewAnotherQueue(result);
              }}
              data-cy="search-for-user"
            />
          </div>
        </Menu>
      </div>
    );
  }
}

export default TasksNavigation;
