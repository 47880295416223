import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import './IconButton.css';

const IconButton = ({
  icon, onClick, type, className, color, loading,
}) => (
  <Button className={classNames('IconButton', className)} icon {...{ type, onClick }}>
    <Icon name={icon} {...{ color, loading }} />
  </Button>
);

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool,
};

IconButton.defaultProps = {
  type: 'button',
};

export default IconButton;
