import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Grid, Form, Table, Search, Message, List, Button, Icon, Loader } from 'semantic-ui-react';
import { FormSpy, Field as FinalField } from 'react-final-form';
import _ from 'lodash';
import { FieldArray } from 'react-final-form-arrays';
import Elasticsearch from '../components/Elasticsearch';
import IconButton from '../components/IconButton';
import Field from '../components/Field';
import InheritanceLockIcon from './InheritanceLockIcon';

const getInsuranceTypes = gql`
  query {
    insuranceTypes: insurance_types {
      id
      name
    }
  }
`;

const getProfile = gql`
  query {
    profile {
      id
      service_area {
        id
        insurance_carriers(limit: 5) {
          id
          name
          service_areas {
            name
          }
        }
      }
    }
  }
`;

const carrierSuggestQuery = ({ q, serviceAreaId, excludeIds }) => ({
  index: `${process.env.REACT_APP_ELASTICSEARCH_INDEX_PREFIX || ''}insurance_carriers`,
  body: {
    query: {
      bool: {
        must: [{
          multi_match: {
            query: q,
            fields: ['name', 'service_areas.name'],
            type: 'cross_fields',
            operator: 'and',
          },
        }],
        must_not: {
          terms: { id: excludeIds },
        },
        should: [{
          term: { 'service_areas.id': serviceAreaId },
        }],
      },
    },
  },
});

const carrierAutocompleteSuggestions = results => results.map(result => ({
  key: result.id.toString(),
  title: result.name,
  description: result.service_areas.map(sa => sa.name).join(', '),
}));

const carrierAutocompleteRenderer = ({ title, description }) => (
  <Grid columns={2}>
    <Grid.Column>
      <div className="title">{title}</div>
      <div className="description">{description}</div>
    </Grid.Column>
    <Grid.Column textAlign="right">
      <Button type="button" size="small" compact>Add ⏎</Button>
    </Grid.Column>
  </Grid>
);

class CarrierSearch extends Component {
  state = {}

  handleSearch= (event) => {
    this.setState({ searchQuery: event.target.value });
  }

  preventSubmission = (event) => {
    // do not want to trigger form validation or submission on 'enter'
    if (event.charCode === 13) {
      event.preventDefault();
    }
  }

  render() {
    return (
      <Elasticsearch
        query={carrierSuggestQuery({
          q: this.state.searchQuery,
          serviceAreaId: this.props.serviceAreaId,
          excludeIds: this.props.excludeIds,
        })}
        skip={!this.state.searchQuery}
        allowSimultaneousQueries
      >
        {({ results, searching }) => (
          <Search
            input={{ icon: 'plus square outline', iconPosition: 'left' }}
            loading={searching}
            fluid
            placeholder="Add another insurance"
            value={this.state.searchQuery}
            noResultsMessage="No matching carriers found."
            onSearchChange={_.throttle(this.handleSearch, 500, { leading: true })}
            results={carrierAutocompleteSuggestions(results)}
            resultRenderer={carrierAutocompleteRenderer}
            onResultSelect={(e, d) => {
              this.props.onResultSelect(d);
              this.setState({ searchQuery: undefined });
            }}
            onKeyPress={this.preventSubmission}
          />
        )}
      </Elasticsearch>
    );
  }
}

const alreadyTakenCarrierIds = values => values.contact_insurances_attributes
  .concat(values.non_accepted_contact_insurances_attributes)
  .map(ci => !ci._destroy && ci.insurance_carrier_id).filter(Boolean);

const suggestedCarriersForUserServiceArea = (values, profile) => profile.service_area
  .insurance_carriers.filter(ic => !alreadyTakenCarrierIds(values).includes(ic.id));

const InsurancesTable = ({ disabled, inheritedInsurances, inheritedNonAcceptedInsurances, parent }) => (
  <Query query={getProfile}>
    {({ loading: loadingProfile, data: { profile } }) => {
      if (loadingProfile) return <Loader active />;
      return (
        <FormSpy>
          {({ form, values }) => (
            <>
              { !disabled && suggestedCarriersForUserServiceArea(values, profile).length > 0 && (
                <Grid.Row>
                  <Grid.Column>
                    <Message info>
                      <Message.Header>Are these insurances accepted?</Message.Header>
                      <p>These are the top insurance providers used by our patients in your service area</p>
                      <List divided relaxed>
                        {suggestedCarriersForUserServiceArea(values, profile).map(ic => (
                          <List.Item key={ic.id}>
                            <List.Content floated="right">
                              <Button
                                type="button"
                                onClick={() => form.mutators.push('contact_insurances_attributes', {
                                  insurance_carrier_id: ic.id,
                                  insurance_carrier_name: ic.name,
                                  insurance_type_ids: [],
                                  insurance_types: [],
                                })}
                              >
                                Yes
                              </Button>
                              <Button
                                type="button"
                                onClick={() => form.mutators.push('non_accepted_contact_insurances_attributes', {
                                  insurance_carrier_id: ic.id,
                                  insurance_carrier_name: ic.name,
                                  insurance_carrier: ic,
                                })}
                              >
                                No
                              </Button>
                            </List.Content>
                            <List.Content>
                              <b>{ic.name}</b>
                              <div>{ic.service_areas.map(sa => sa.name).join(', ')}</div>
                            </List.Content>
                          </List.Item>
                        ))}
                      </List>
                    </Message>
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row>
                <Grid.Column>
                  <Form.Field>
                    <label>
                      <Icon name="check circle" color="olive" />
                      Accepted Insurances
                    </label>
                    <Table>
                      <Table.Body>
                        { !disabled ? (
                          <>
                            { values.contact_insurances_attributes.filter(e => !e._destroy).length === 0 && (
                              <Table.Row textAlign="center">
                                <Table.Cell colSpan="3">
                                  <i className="notice">No insurances</i>
                                </Table.Cell>
                              </Table.Row>
                            )}
                            <FieldArray name="contact_insurances_attributes">
                              {({ fields }) => fields.map((name, index) => (
                                <Table.Row
                                  key={name}
                                  className={fields.value[index]._destroy && 'hidden'}
                                >
                                  <Table.Cell width="7">
                                    <b>{fields.value[index].insurance_carrier_name}</b>
                                  </Table.Cell>
                                  <Table.Cell width="8">
                                    <Query query={getInsuranceTypes}>
                                      {({ loading: loadingInsuranceTypes, data: { insuranceTypes } }) => (
                                        <Field.Dropdown
                                          name={`${name}.insurance_type_ids`}
                                          placeholder="Select plan types"
                                          search
                                          value={fields.value[index].insurance_type_ids}
                                          multiple
                                          openOnFocus={false}
                                          loading={loadingInsuranceTypes}
                                          options={(insuranceTypes || [])
                                            .map(s => ({ key: s.id, text: s.name, value: s.id }))}
                                        />
                                      )}
                                    </Query>
                                  </Table.Cell>
                                  <Table.Cell width="1" textAlign="center">
                                    <FinalField name={`${name}._destroy`}>
                                      {({ input }) => (
                                        <IconButton icon="trash" onClick={() => input.onChange(true)} />
                                      )}
                                    </FinalField>
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </FieldArray>
                          </>
                        ) : (
                          <>
                            { (inheritedInsurances || values.contact_insurances_attributes).length === 0 ? (
                              <Table.Row textAlign="center">
                                <Table.Cell colSpan="3">
                                  <i className="notice">No insurances</i>
                                </Table.Cell>
                                {(disabled && inheritedInsurances) && (
                                  <Table.Cell textAlign="right">
                                    <InheritanceLockIcon
                                      parent={parent}
                                      popupHorizontalOffset={7}
                                    />
                                  </Table.Cell>
                                )}
                              </Table.Row>
                            ) : (
                              (inheritedInsurances || values.contact_insurances_attributes).map(ins => (
                                <Table.Row key={ins.id}>
                                  <Table.Cell width="7">
                                    {ins.insurance_carrier_name}
                                  </Table.Cell>
                                  <Table.Cell width="8">
                                    { ins.insurance_types.map(type => type.name).join(', ')}
                                  </Table.Cell>
                                  {(disabled && inheritedInsurances) && (
                                    <Table.Cell textAlign="right">
                                      <InheritanceLockIcon
                                        parent={parent}
                                        popupHorizontalOffset={7}
                                      />
                                    </Table.Cell>
                                  )}
                                </Table.Row>
                              ))
                            )}
                          </>
                        )}
                      </Table.Body>
                      { !disabled && (
                        <Table.Footer>
                          <Table.Row>
                            <Table.HeaderCell colSpan={3}>
                              <CarrierSearch
                                serviceAreaId={profile.service_area.id}
                                excludeIds={alreadyTakenCarrierIds(values)}
                                onResultSelect={(data) => {
                                  form.mutators.push('contact_insurances_attributes', {
                                    insurance_carrier_id: data.result.key,
                                    insurance_carrier_name: data.result.title,
                                    insurance_type_ids: [],
                                  });
                                }}
                              />
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Footer>
                      )}
                    </Table>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field>
                    <label htmlFor="non-accepted-inusrances">
                      <Icon name="remove circle" color="red" />
                      Non-Accepted Insurances
                    </label>
                    <Table>
                      <Table.Body>
                        { !disabled ? (
                          <>
                            { values.non_accepted_contact_insurances_attributes.filter(e => !e._destroy).length === 0 && (
                              <Table.Row textAlign="center">
                                <Table.Cell colSpan="3">
                                  <i className="notice">No insurances</i>
                                </Table.Cell>
                              </Table.Row>
                            )}
                            <FieldArray
                              name="non_accepted_contact_insurances_attributes"
                              isEqual={(a, b) => a === b}
                            >
                              {({ fields }) => fields.map((name, index) => (
                                <Table.Row
                                  key={name}
                                  className={fields.value[index]._destroy && 'hidden'}
                                >
                                  <Table.Cell width="7">
                                    <b>{fields.value[index].insurance_carrier_name}</b>
                                  </Table.Cell>
                                  <Table.Cell width="8" />
                                  <Table.Cell width="1" textAlign="center">
                                    <FinalField name={`${name}._destroy`}>
                                      {({ input }) => (
                                        <IconButton icon="trash" onClick={() => input.onChange(true)} />
                                      )}
                                    </FinalField>
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </FieldArray>
                          </>
                        ) : (
                          <>
                            { (inheritedNonAcceptedInsurances || values.non_accepted_contact_insurances_attributes).length === 0 ? (
                              <Table.Row textAlign="center">
                                <Table.Cell colSpan="3">
                                  <i className="notice">No insurances</i>
                                </Table.Cell>
                                {(disabled && inheritedNonAcceptedInsurances) && (
                                  <Table.Cell textAlign="right">
                                    <InheritanceLockIcon
                                      parent={parent}
                                      popupHorizontalOffset={7}
                                    />
                                  </Table.Cell>
                                )}
                              </Table.Row>

                            ) : (
                              (inheritedNonAcceptedInsurances || values.non_accepted_contact_insurances_attributes).map(ins => (
                                <Table.Row key={ins.id}>
                                  <Table.Cell width="7">
                                    {ins.insurance_carrier.name}
                                  </Table.Cell>
                                  <Table.Cell width="8"></Table.Cell>
                                  {(disabled && inheritedNonAcceptedInsurances) && (
                                    <Table.Cell textAlign="right">
                                      <InheritanceLockIcon
                                        parent={parent}
                                        popupHorizontalOffset={7}
                                      />
                                    </Table.Cell>
                                  )}
                                </Table.Row>
                              ))
                            )}
                          </>
                        )}
                      </Table.Body>
                      { !disabled && (
                        <Table.Footer>
                          <Table.Row>
                            <Table.HeaderCell colSpan={3}>
                              <CarrierSearch
                                serviceAreaId={profile.service_area.id}
                                excludeIds={alreadyTakenCarrierIds(values)}
                                onResultSelect={(data) => {
                                  form.mutators.push('non_accepted_contact_insurances_attributes', {
                                    insurance_carrier_id: data.result.key,
                                    insurance_carrier_name: data.result.title,
                                    insurance_type_ids: [],
                                  });
                                }}
                              />
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Footer>
                      )}
                    </Table>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        </FormSpy>
      );
    }}
  </Query>
);

export default InsurancesTable;
