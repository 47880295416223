import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { Field as FinalField } from 'react-final-form';
import ErrorMessage from './ErrorMessage';

const Field = ({
  children, name, label, required, inline, format, validate,
}) => (
  <FinalField name={name} parse={null} {...{ format, validate }}>
    {({ input, meta }) => (
      <Form.Field {...{ required, inline }}>
        { label && <label>{label}</label> }
        { children({ input, meta }) }
        <ErrorMessage {...meta} />
      </Form.Field>
    )}
  </FinalField>
);

Field.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.func,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  required: PropTypes.bool,
  inline: PropTypes.bool,
  validate: PropTypes.func,
  format: PropTypes.func,
};

export default Field;
