import React from 'react';
import { TextArea as SemanticTextArea } from 'semantic-ui-react';
import Field from './Field';

const TextArea = ({
  name, label, required, inline, validate, format, value, ...otherProps
}) => (
  <Field {... {
    name, label, required, inline, validate, format,
  }}
  >
    {({ input }) => (
      <SemanticTextArea {...input} value={value || input.value} {...otherProps} />
    )}
  </Field>
);

export default TextArea;
