import React, { Component } from 'react';
import { Grid, Button, Modal } from 'semantic-ui-react';
import { FormSpy } from 'react-final-form';
import ParentSearchBox from './ParentSearchBox';
import InheritanceDiffTable from './InheritanceDiffTable';
import './ParentModal.css';

const selectParent = (form, parent, onModalClose) => {
  onModalClose();
  form.change('parent', parent);
};

const parentHasChanged = (contact, parent) => {
  if ((!contact.parent && (parent && parent.id)) || (contact.parent && (contact.parent.id !== parent.id))) {
    return true;
  }
  return false;
};

class ParentModal extends Component {
  state = { newParent: {} };

  setNewParent = (newParent) => {
    this.setState({ newParent });
  }

  render() {
    return (
      <FormSpy>
        {({ form }) => (
          <Modal
            size="large"
            open={this.props.modalOpen}
            className="ParentModal"
          >
            <Modal.Header>Associate with a Practice or Group</Modal.Header>
            <Modal.Content scrolling={parentHasChanged(this.props.contact, this.state.newParent)}>
              <Modal.Description>
                <Grid.Row>
                  <Grid.Column>
                    <ParentSearchBox
                      onResultSelect={this.setNewParent}
                      descendants={this.props.descendants}
                      contactId={this.props.contact.id}
                    />
                    {this.state.newParent && this.state.newParent.display_name
                    && <InheritanceDiffTable newParent={this.state.newParent} existingContact={this.props.contact} key={this.state.newParent.id} />}
                  </Grid.Column>
                </Grid.Row>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                disabled={!this.state.newParent || !this.state.newParent.display_name}
                color={this.state.newParent ? 'blue' : null}
                type="button"
                floated="right"
                onClick={() => selectParent(form, this.state.newParent, this.props.onModalClose)}
              >
                Associate
              </Button>
              <Button
                type="button"
                floated="right"
                onClick={() => selectParent(form, this.props.contact.parent, this.props.onModalClose)}
              >
                Cancel
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </FormSpy>
    );
  }
};

export default ParentModal;
