import React from 'react';
import { Grid, Popup } from 'semantic-ui-react';
import Field from '../components/Field';
import { presence } from './Validators';
import {FormSpy} from "react-final-form";

export default ({ isContactAdmin }) => (
  <FormSpy>
    {({ values: { is_partner } }) => {
      const cannotEditPartner = is_partner && !isContactAdmin;

      return (
        <Grid padded="vertically">
          <Grid.Row>
            <Grid.Column>
              <Popup
                trigger={(
                  <Field.Checkbox
                    disabled={cannotEditPartner}
                    name="is_complete"
                    label="The information on this contact is complete and correct"
                  />
                )}
                content="
                A complete contact usually has an NPI, Phone, Fax, and full insurance info.
                Use your best judgement! If it feels complete, check the box. If more work
                is needed, leave a note as to why so the next person can complete the contact.
              "
              />
            </Grid.Column>
          </Grid.Row>
          <Field.Condition when="is_complete" is={false}>
            <Grid.Row>
              <Grid.Column>
                <Field.TextArea
                  name="incomplete_reason"
                  label="Notes on what information is still needed"
                  placeholder='Let your team know what additional information this contact would need to be complete.
                  "Full insurance list needed" or "Need to get NPI and Tax-ID" are common examples.'
                  maxLength={500}
                  required
                  validate={presence}
                  disabled={cannotEditPartner}
                />
              </Grid.Column>
            </Grid.Row>
          </Field.Condition>
        </Grid>
      );
    }}
  </FormSpy>
);
