import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Grid } from 'semantic-ui-react';
import { FormSpy } from 'react-final-form';
import Section from './Section';
import InsurancesTable from './InsurancesTable';
import Field from '../components/Field';
import './InsurancesSection.css';
import InheritedAttribute from './InheritedAttribute';
import InheritanceLockIcon from './InheritanceLockIcon';

const getInsuranceNetworks = gql`
  query {
    insuranceNetworks: insurance_networks { id, name }
  }
`;

export default ({ isContactAdmin }) => (
  <FormSpy>
    {({ form, values }) => {
      const cannotEditPartner = values.is_partner && !isContactAdmin;

      return (
        <InheritedAttribute name="contact_insurances">
          {({ disabled: insuranceDisabled, inheritedValue: inheritedInsurances, parent }) => (
            <InheritedAttribute name="non_accepted_contact_insurances">
              {({ disabled: nonAcceptedInsurancesDisabled, inheritedValue: inheritedNonAcceptedInsurances }) => (
                <Section
                  className="InsurancesSection"
                  title="Insurances"
                  right={(
                    <>
                      { !(insuranceDisabled || nonAcceptedInsurancesDisabled || cannotEditPartner) && (
                        <Field.Checkbox
                          name="accepts_insurance"
                          label="Accepts Insurance"
                          toggle
                          onChange={(newValue) => {
                            // destroy all insurances when not accepting insurance
                            if (!newValue) {
                              form.change(
                                'contact_insurances_attributes',
                                values.contact_insurances_attributes.map(ci => ({ ...ci, _destroy: true })),
                              );
                              form.change(
                                'non_accepted_contact_insurances_attributes',
                                values.non_accepted_contact_insurances_attributes.map(ci => ({ ...ci, _destroy: true })),
                              );
                            }
                          }}
                        />
                      )}
                    </>
                  )}
                >
                  { ((insuranceDisabled || nonAcceptedInsurancesDisabled) || values.accepts_insurance) && (
                    <>
                      <InsurancesTable
                        disabled={cannotEditPartner || insuranceDisabled || nonAcceptedInsurancesDisabled}
                        inheritedInsurances={inheritedInsurances}
                        inheritedNonAcceptedInsurances={inheritedNonAcceptedInsurances}
                        parent={parent}
                      />

                      <InheritedAttribute name="insurance_networks">
                        {({ disabled, inheritedValue: inheritedInsuranceNetworks }) => (
                          <Grid.Row>
                            <Grid.Column>
                              { (insuranceDisabled || nonAcceptedInsurancesDisabled || disabled || cannotEditPartner) ? (
                                <Field.Input
                                  label="Accepted Networks"
                                  name="networks"
                                  icon={parent
                                    ? <InheritanceLockIcon parent={parent} popupHorizontalOffset={-5} /> : null}
                                  value={(inheritedInsuranceNetworks
                                    || values.insurance_networks || []).map(i => i.name).join(', ')}
                                  disabled={cannotEditPartner || disabled}
                                >
                                </Field.Input>
                              ) : (
                                <Query query={getInsuranceNetworks}>
                                  {({ loading, data: { insuranceNetworks } }) => (
                                    <Field.Dropdown
                                      name="insurance_network_ids"
                                      label="Accepted Networks"
                                      multiple
                                      search
                                      openOnFocus={false}
                                      loading={loading}
                                      options={(insuranceNetworks || [])
                                        .map(i => ({ key: i.id, text: i.name, value: i.id }))}
                                    />
                                  )}
                                </Query>
                              )}
                            </Grid.Column>
                          </Grid.Row>
                        )}
                      </InheritedAttribute>
                    </>
                  )}
                  <InheritedAttribute name="insurance_notes">
                    {({ disabled: insuranceNotesDisabled, inheritedValue: inheritedInsuranceNotes, parent: insuranceNotesParent }) => (
                      <Grid.Row>
                        <Grid.Column>
                          <Field.TextArea
                            name="insurance_notes"
                            value={inheritedInsuranceNotes}
                            disabled={cannotEditPartner || insuranceNotesDisabled}
                            label={(
                              <div className="insurance-section-label">
                                <div>Insurance Notes</div>
                                {insuranceNotesParent && (
                                  <div>
                                    <InheritanceLockIcon parent={insuranceNotesParent} popupHorizontalOffset={-5} />
                                  </div>
                                )}
                              </div>
                            )}
                            maxLength={500}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    )}
                  </InheritedAttribute>
                </Section>
              )}
            </InheritedAttribute>
          )}
        </InheritedAttribute>
      );
    }}
  </FormSpy>
);
