import React from 'react';
import { Button, Modal, Segment } from 'semantic-ui-react';
import './EditParentWarningModal.css';

const EditParentWarningModal = ({
  descendants, contactKind, submitting, modalOpen, onModalClose, confirm,
}) => (
  <Modal
    size="tiny"
    open={modalOpen}
    className="EditParentWarningModal"
  >
    <Modal.Header>Are you sure?</Modal.Header>
    <Modal.Content>
      {`Your changes will also be applied to the following ${descendants.length > 1 ? 'contacts' : 'contact'}
      associated with this ${contactKind === 'lab_provider' ? 'diagnositic facility' : contactKind}:`}
      <Segment compact>
        {descendants.map((descendant, index) => index < 5 && <div key={descendant.id}>{descendant.display_name}</div>)}
        {descendants.length > 5 && <div>{`+${descendants.length - 5} more associated contacts`}</div>}
      </Segment>

      Do you still want to make these changes?
    </Modal.Content>
    <Modal.Actions>
      <Button
        type="submit"
        color="blue"
        floated="right"
        disabled={submitting}
        loading={submitting}
        onClick={confirm}
      >
        {'Confirm & Save'}
      </Button>
      <Button
        type="button"
        floated="right"
        onClick={onModalClose}
      >
        Cancel
      </Button>
    </Modal.Actions>
  </Modal>
);

export default EditParentWarningModal;
