import React from 'react';
import qs from 'qs';
import { Grid, Loader, Segment, Pagination } from 'semantic-ui-react';
import Elasticsearch from '../components/Elasticsearch';
import Geocoder from '../components/Geocoder';
import ContactsList from './ContactsList';
import ProviderList from '../provider/ProviderList';
import './SearchResults.css';

const taskPageSize = 10;

export default (props) => {
  const { q, page, loc } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  return (
    <Grid className="SearchResults" centered>
      <Geocoder address={loc}>
        {({ processing, location }) => {
          if (processing) return <Loader size="large" active>Geocoding</Loader>;

          return (
            <Elasticsearch query={props.query(location)}>
              {({ searching, results, total }) => {
                if (searching) return <Loader size="large" active>Searching</Loader>;
                if (results.length === 0) {
                  return (
                    <Grid.Row>
                      <h2 className="notice">{`No matching ${props.mode}s found.`}</h2>
                    </Grid.Row>
                  );
                }

                return (
                  <>
                    <Grid.Row className="condensed">
                      <Grid.Column>
                        <Segment basic>
                          {total}
                          {` matching ${props.mode}`}
                          {total > 1 ? 's' : null}
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        {props.mode === 'contact'
                          && <ContactsList results={results} search={q} />}
                        {props.mode === 'provider'
                          && <ProviderList results={results} query={q} />}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      { total > taskPageSize && (
                        <Pagination
                          defaultActivePage={page || 1}
                          totalPages={Math.ceil(total / taskPageSize)}
                          onPageChange={(event, data) => {
                            const baseUrl = props.mode === 'contact' ? '/contacts' : '/provider-directory';
                            let nextPageUrl = `${baseUrl}/search?page=${data.activePage}&q=${encodeURIComponent(q)}`;
                            if (loc) nextPageUrl += `&loc=${encodeURIComponent(loc)}`;
                            props.history.push(nextPageUrl);
                          }}
                          firstItem={null}
                          lastItem={null}
                        />
                      )}
                    </Grid.Row>
                  </>
                );
              }}
            </Elasticsearch>
          );
        }}
      </Geocoder>
    </Grid>
  );
};
