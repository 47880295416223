import React from 'react';
import { Grid, Header, Label, Icon, Image } from 'semantic-ui-react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesOctagon } from '@fortawesome/pro-regular-svg-icons';
import './ProviderList.css';

const oneLifeUrl = process.env.REACT_APP_AUTH_PROVIDER_URL;

const pointImageUrlToProd = imageURL => imageURL.replace(
  new RegExp('.*/'),
  'https://s3.amazonaws.com/prod_1life_doc_bucket/',
);

export default ({ results, query }) => (
  <Grid padded="vertically" className="ProviderList">
    { results.map(result => (
      <Grid.Row key={result.id}>

        <Grid.Column>
          <div className="image-cropper">
            {result.profile_image_url && <Image src={pointImageUrlToProd(result.profile_image_url)} size="large" />}
          </div>
        </Grid.Column>
        <Grid.Column width={7}>
          <Header>
            <Header.Content>
              <span className="truncate">{ result.sig_name }</span>
            </Header.Content>
          </Header>
          <Grid.Row className="specialty">
            { result.service_types && result.service_types
              .map(s => <Label basic key={s.id}>{s.name}</Label>)
            }
            { result.specialty_types && result.specialty_types
              .map(s => <Label basic key={s.id}>{s.name}</Label>)
            }
          </Grid.Row>

          {result.work_schedule && (
            <Grid.Row className="emphasized">
              <a
                href={`${oneLifeUrl}/admin/providers/${result.id}/appointments`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon color="blue" name="calendar" />
                {result.work_schedule.out && !result.work_schedule.on_vacation
                    && (
                    <span>
                      Out of office until:
                      {' '}
                    </span>
                    )}
                {result.work_schedule.out && result.work_schedule.on_vacation
                    && (
                    <span>
                      On leave until:
                      {' '}
                    </span>
                    )}
                {result.work_schedule && result.work_schedule.out
                    && (
                    <span>{moment(result.work_schedule.returning_on_date).format('ddd, MMMM Do YYYY')}</span>
                    )}
                {result.work_schedule && result.work_schedule.comment
                    && (
                    <span>
                      Note:
                      {' '}
                      {result.work_schedule.comment}
                    </span>
                    )}
                {result.work_schedule && !result.work_schedule.out && (
                  <span>View Appointment Schedule</span>
                )}
              </a>
            </Grid.Row>
          )}

          {result.panel && (
            <Grid.Row className="emphasized">
              {result.panel.state === 'open'
                && (
                <div className="positive">
                  <Icon name="check" />
                  Open panel
                </div>
                )}
              {result.panel.state === 'closed'
                && (
                <div className="warning">
                  <FontAwesomeIcon icon={faTimesOctagon} />
                  {'  '}
                  Closed panel
                </div>
                )}
              {result.panel.state === 'waitlist'
                && (
                  <div className="warning">
                    <Icon color="orange" name="warning" />
                      Panel waitlisted
                  </div>
                )
              }
            </Grid.Row>
          )}

          <Grid.Row className="office-info">
            <div className="emphasized">
              {result.default_office && <span>{result.default_office.name}</span>}
              {result.default_office && result.service_area && <span> - </span>}
              {result.service_area && <span>{result.service_area.name}</span>}
            </div>
            {result.default_office && result.default_office.phone && (
              <div>
                <span className="section-header emphasized">OFFICE</span>
                <span>
                  {' '}
                  {result.default_office.phone}
                </span>
              </div>
            )}
            {result.address && (
            <div>
              {`${result.address.address1}, `}
              {result.address.address2 && `${result.address.address2}`}
              { result.address.address2 && ', '}
              {`${result.address.city}, ${result.address.state.short_name}
                ${result.address.zip}`}
            </div>
            )}
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={7}>
          <div className="emphasized section-header">
            EXPERTISE
          </div>
          <div>
            { result.expertise_types && result.expertise_types
              .map((s, index) => (
                <span key={s.id}>
                  {s.name.toLowerCase().includes(query.toLowerCase()) ? (<strong>{s.name}</strong>) : s.name }
                  {result.expertise_types.length - 1 !== index && ', '}
                </span>
              ))
            }
          </div>
          { result.specialized_procedures_performed.length > 0 && (
          <div>
            <div className="emphasized procedures-header section-header">
              PROCEDURES
            </div>
            <div>
              { result.specialized_procedures_performed
                .map((s, index) => (
                  <span key={s.id}>
                    {s.lay_description.toLowerCase().includes(query.toLowerCase())
                      ? (<strong>{s.lay_description}</strong>) : s.lay_description }
                    {result.specialized_procedures_performed.length - 1 !== index && ', '}
                  </span>
                ))
              }
            </div>
          </div>
          )}
          <div>
            Updated
            {' '}
            {moment(result.updated_at).fromNow()}
          </div>
        </Grid.Column>
      </Grid.Row>
    ))}
  </Grid>
);
