import React, { Component } from 'react';
import { Grid, Search } from 'semantic-ui-react';
import _ from 'lodash';
import { format } from 'libphonenumber-js';
import uniqid from 'uniqid';
import Elasticsearch from '../components/Elasticsearch';
import roles from '../data/roles';
import './PatientSearchBox.css';

const maxResults = 7;
const patientQuery = ({ q }) => ({
  index: `${process.env.REACT_APP_ELASTICSEARCH_INDEX_PREFIX || ''}patients`,
  size: maxResults,
  body: {
    query: {
      bool: {
        must: [
          {
            multi_match: {
              type: 'cross_fields',
              operator: 'and',
              query: q,
              fields: ['first_name', 'last_name', 'nickname', 'phone', 'dob_string', 'email'],
            },
          },
        ],
        filter: {
          term: { is_duplicate: false },
        },
      },
    },
    highlight: {
      fields: {
        phone: {},
      },
    },
  },
});

const onelifeUrl = process.env.REACT_APP_AUTH_PROVIDER_URL;
const chartUrl = process.env.REACT_APP_CLINICAL_UX_URL;

class PatientSearchBox extends Component {
  state = {
    active: false,
    searchQuery: '',
  };

  linkToPatient = (patientId) => {
    const isAdmin = this.props.profile.roles.map(r => r.id).includes(roles.ADMIN);

    return isAdmin ? `${onelifeUrl}/${patientId}/account` : `${chartUrl}/#/patients/${patientId}/chart`;
  };


  allResultsLink = () => `${onelifeUrl}/admin/search?utf8=✓&name=${this.state.searchQuery}&service_area_id=all`;

  buildQuerySuggestions = (results, total) => {
    const patientResults = results.map((hit) => {
      const result = hit._source;
      const displayFirstName = (result.nickname ? `${result.first_name} '${result.nickname}'` : result.first_name);
      const contactInfo = hit.highlight && hit.highlight.phone ? format(result.phone[0], 'US', 'National') : result.email;
      return {
        key: uniqid(),
        id: result.id,
        display_dob: result.display_dob,
        title: `${displayFirstName} ${result.last_name}`,
        description: `${result.display_dob} (${result.formatted_age}) &middot; ${contactInfo}`,
        link: this.linkToPatient(result.id),
      };
    });

    return total > maxResults && (this.props.mode === 'link') ? patientResults.concat([{
      key: uniqid(),
      title: 'View all results',
      link: this.allResultsLink(),
    }]) : patientResults;
  };

  handleQueryChange = (event) => {
    const query = event.target.value;
    this.setState({ searchQuery: query });
  };

  highlight = (result) => {
    const escapedQuery = _.escapeRegExp(this.state.searchQuery);
    const re = new RegExp(`(${escapedQuery})`, 'i');

    const highlight = text => (text ? text.replace(re, '<span class="highlighted">$1</span>') : '');

    if (re.test(result)) {
      return highlight(result);
    }

    return result;
  };

  onResultSelect = (event, data) => {
    if (this.props.mode === 'link') {
      this.openPatientLink(event, data);
    } else {
      this.props.onResultSelect(data.result);
    }
  }

  openPatientLink = (event, data) => {
    window.open(data.result.link, '_blank', 'noopener noreferrer');
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter' && this.state.searchQuery && this.props.mode === 'link') {
      window.open(this.allResultsLink(), '_blank', 'noopener noreferrer');
    }
  };

  suggestionRenderer = ({ title, description }) => (
    <Grid verticalAlign="middle">
      <Grid.Row>
        <Grid.Column>
          <div className="title" dangerouslySetInnerHTML={{ __html: this.highlight(title) }} />
          <div className="description" dangerouslySetInnerHTML={{ __html: this.highlight(description) }} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  toggleSize = () => this.setState(prevState => ({
    active: !prevState.active,
  }));

  render() {
    return (
      <div className="PatientSearchBox">
        <Elasticsearch
          query={patientQuery({ q: this.state.searchQuery })}
          unwrap={false}
          skip={!this.state.searchQuery}
          allowSimultaneousQueries
        >
          {({ results, searching, total }) => (
            <Search
              input={{
                fluid: true,
                inverted: true,
              }}
              className={this.state.active ? 'expanded small' : 'small'}
              fluid
              loading={searching}
              placeholder={this.state.active ? 'Search by name, DOB, phone, or email' : 'Patient search'}
              value={this.state.searchQuery}
              showNoResults={false}
              onSearchChange={_.throttle(this.handleQueryChange, 500, { leading: true })}
              results={this.buildQuerySuggestions(results, total)}
              resultRenderer={this.suggestionRenderer}
              onResultSelect={this.onResultSelect}
              onFocus={this.toggleSize}
              onBlur={this.toggleSize}
              onKeyPress={this.handleKeyPress}
            />
          )}
        </Elasticsearch>
      </div>
    );
  }
}

export default PatientSearchBox;
