const roles = Object.freeze({
  ADMIN: 2,
  CONTACT_ADMIN: 6,
  VMT: 12,
  BILLING_ADMIN: 23,
  ADMIN_TRAINEE: 67,
  MEDICAL_RECORDS_ADMIN: 68,
  AUTHS_ADMIN: 69,
  FRONT_DESK_ADMIN: 70,
  VMT_FOCUS: 71,
});

export default roles;
