import React from 'react';
import { Input as SemanticInput } from 'semantic-ui-react';
import Field from './Field';

const Input = ({
  name, label, required, inline, validate, format, value, ...otherProps
}) => (
  <Field {... {
    name, label, required, inline, validate, format,
  }}
  >
    {({ input }) => (
      <SemanticInput {...input} value={value || input.value} {...otherProps} />
    )}
  </Field>
);

Input.defaultProps = {
  maxLength: 255,
};

export default Input;
