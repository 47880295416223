import React, { Component } from 'react';
import { Search, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import uniqid from 'uniqid';
import Elasticsearch from '../components/Elasticsearch';
import '../components/SearchBox.css';

const suggestQuery = ({ q, excludeIds }) => ({
  index: `${process.env.REACT_APP_ELASTICSEARCH_INDEX_PREFIX || ''}contacts`,
  body: {
    query: {
      bool: {
        must: [
          {
            match: {
              company_name: {
                query: q,
                operator: 'and',
              },
            },
          },
          { term: { is_active: true } },
        ],
        must_not: {
          terms: { _id: excludeIds },
        },
        filter: {
          bool: {
            should: [
              { term: { kind: 'practice' } },
              { term: { kind: 'group' } },
            ],
          },
        },
      },
    },
  },
});

const excludeIds = (descendants, id) => {
  const descendantIds = (descendants || []).map(decendant => decendant.id);
  return id ? descendantIds.concat(id) : descendantIds;
};

class ParentSearchBox extends Component {
  state = { query: '', skipSearch: true };

  buildQuerySuggestions = results => results.map(result => ({
    key: uniqid(),
    id: result.id,
    title: result.company_name,
    description: result.company_name,
    source: result,
    highlight: result.company_name.replace(
      new RegExp(`\\b${_.escapeRegExp(this.state.query)}`, 'gi'),
      match => `<em>${match}</em>`,
    ),
  }));

  handleQueryChange = (event) => {
    this.setState({ query: event.target.value, skipSearch: false });
  };

  suggestionRenderer = ({ highlight, source }) => (
    <>
      {/* eslint-disable-next-line react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: highlight }} />
      { source.kind === 'group' && <Icon name="sitemap" style={{ marginLeft: 5 }} /> }
    </>
  );

  render() {
    return (
      <Elasticsearch
        mode="search"
        query={suggestQuery({
          q: this.state.query,
          excludeIds: excludeIds(this.props.descendants, this.props.contactId),
        })}
        skip={this.state.skipSearch}
        allowSimultaneousQueries
      >
        {({ results, searching }) => (
          <Search
            fluid
            loading={searching}
            placeholder="Search for group or practice"
            value={this.state.query}
            minCharacters={1}
            onSearchChange={_.throttle(this.handleQueryChange, 500, { leading: true })}
            results={this.buildQuerySuggestions(results)}
            onBlur={(e) => {
              if (e.target.value.length === 0) {
                this.setState({ query: e.target.value, skipSearch: true });
              } else {
                this.setState({
                  query: '',
                });
              }
            }}
            onResultSelect={(e, d) => {
              this.setState({ query: d.result.title, skipSearch: true });
              this.props.onResultSelect(d.result.source);
            }}
            resultRenderer={this.suggestionRenderer}
          />
        )}
      </Elasticsearch>
    );
  }
}

export default ParentSearchBox;
