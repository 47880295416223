import React from 'react';
import moment from 'moment';
import gql from 'graphql-tag';
import { NavLink } from 'react-router-dom';
import { Query } from 'react-apollo';
import { Menu, Dropdown } from 'semantic-ui-react';
import Logo from '../components/Logo';
import UserMenu from './UserMenu';
import mixpanel from '../mixpanel';
import './NavBar.css';
import PatientSearchBox from './PatientSearchBox';

const getProfile = gql`
  query Profile {
    profile {
      id
      default_office {
        id
      }
      display_name
      roles { id }
      service_area {
        id
        default_office {
          id
        }
        service_area_group {
          id
          service_areas { id }
        }
      }
    }
  }
`;

const getFeatureFlags = gql`
  query {
    featureFlags: feature_flags
  }
`;

const NavBar = () => (
  <Menu inverted className="NavBar">
    <Query query={getProfile} onCompleted={({ profile }) => mixpanel.identify(profile.id)}>
      {({ loading: loadingProfile, data: { profile } }) => (
        <Query query={getFeatureFlags}>
          {({ loading: loadingFeatureFlags, data: { featureFlags } }) => {
            if (loadingProfile || loadingFeatureFlags) return null;
            const oneLifeUrl = process.env.REACT_APP_AUTH_PROVIDER_URL;
            const serviceAreas = profile.service_area.service_area_group.service_areas;
            const serviceAreasToUrl = (service_areas) => {
              service_areas.map(sa => `service_area[]=${sa.id}-ServiceArea`).join('&');
            };
            const scheduleAppUrl = process.env.REACT_APP_SCHEDULE_UI_URL;
            const defaultOffice = profile.default_office
              ? profile.default_office.id : profile.service_area.default_office.id;
            const todaysDate = moment().format('YYYY-MM-DD');
            const useNewCheckInUI = featureFlags && featureFlags.includes('new_check_in_schedule_ui');
            const appointmentsUrl = useNewCheckInUI
              ? `${scheduleAppUrl}/appointments`
              : `${oneLifeUrl}/admin/offices/${defaultOffice}/appointments`;
            const walkInVisitsUrl = useNewCheckInUI
              ? `${scheduleAppUrl}/walk-in-visits`
              : `${oneLifeUrl}/admin/offices/${defaultOffice}/walkin_visits?date=${todaysDate}`;
            const providerScheduleUrl = useNewCheckInUI
              ? `${scheduleAppUrl}/provider-schedule`
              : `${oneLifeUrl}/admin/providers/${profile.id}/appointments`;

            return (
              <>
                <Menu.Item><a href={`${oneLifeUrl}/admin`}><Logo title="Home" /></a></Menu.Item>
                <Menu.Item name="tasks" as={NavLink} to="/tasks" target="_self" data-cy="tasks-nav" />
                <Dropdown
                  item
                  trigger={(
                    <>
                      Appointments
                    </>
                  )}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      name="appointments"
                      href={appointmentsUrl}
                      target="_self"
                      data-cy="appointments-nav"
                    >
                      Appointments
                    </Dropdown.Item>
                    <Dropdown.Item
                      name="provider-schedule"
                      href={providerScheduleUrl}
                      target="_self"
                      data-cy="provider-schedule-nav"
                    >
                      Provider Schedule
                    </Dropdown.Item>
                    <Dropdown.Item
                      name="walk-in-visits"
                      href={walkInVisitsUrl}
                      target="_self"
                      data-cy="walk-in-visits-nav"
                    >
                      Walk In Visits
                    </Dropdown.Item>
                    <Dropdown.Item
                      name="office-schedule"
                      href={`${oneLifeUrl}/admin/offices/${defaultOffice}/schedules?date=${todaysDate}`}
                      target="_self"
                      data-cy="office-schedule-nav"
                    >
                      Office Schedule
                    </Dropdown.Item>
                    <Dropdown.Item
                      name="virtual-visits"
                      href={`${oneLifeUrl}/admin/video_appointments`}
                      target="_self"
                      data-cy="virtual-visits-nav"
                    >
                      Virtual Visits
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Menu.Item
                  name="docs"
                  href={`${oneLifeUrl}/admin/inbox?${serviceAreasToUrl(serviceAreas)}`}
                  target="_self"
                  data-cy="docs-nav"
                />
                <Menu.Item name="faxes" href={`${oneLifeUrl}/fax/send_fax`} target="_self" data-cy="faxes-nav" />
                <Menu.Item name="contacts" as={NavLink} to="/contacts" target="_self" data-cy="contacts-nav" />
                <Menu.Item
                  name="providers"
                  as={NavLink}
                  to="/provider-directory"
                  target="_self"
                  data-cy="providers-nav"
                />
                <Menu.Menu position="right">
                  <PatientSearchBox profile={profile} mode="link" data-cy="patient-search-nav" />
                  <UserMenu profile={profile} data-cy="profile-name-nav" />
                </Menu.Menu>
              </>
            );
          }}
        </Query>
      )}
    </Query>
  </Menu>
);

export default NavBar;
