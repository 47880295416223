import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import { Button, Input, Modal, Loader, Label, Icon } from 'semantic-ui-react';
import taskTemplates from '../data/taskTemplates';
import PatientSearchBox from '../app/PatientSearchBox';
import './CreateButton.css';

const createTaskMutation = gql`
  mutation($task_template_id: ID!, $name: String, $keywords: String, $patient_id: ID) {
    create_task(input: {
      task_template_id: $task_template_id,
      taskTemplateId: $task_template_id, # 1Life's schema generation is broken. this field should not exist
      name: $name,
      keywords: $keywords,
      patient_id: $patient_id,
    }) {
      success
      errors
      task { id }
    }
  }
`;

const getProfile = gql`
  query Profile {
    profile {
      id
      roles {
        id
        assignable
      }
    }
  }
`;

class CreateButton extends Component {
  state = { open: false, description: '', chosenPatient: null }

  handleOpen = () => this.setState({ open: true })

  handleClose = () => this.setState({ open: false, description: '', chosenPatient: null })

  handleDescriptionChange = (event) => {
    this.setState({ description: event.target.value });
  }

  handlePatientSelection = (chosenPatient) => {
    this.setState({
      chosenPatient,
    });
  }

  clearPatientSelection = () => {
    this.setState({ chosenPatient: null });
  }

  handleSave = async (mutation) => {
    await mutation({
      variables: {
        task_template_id: taskTemplates.AD_HOC,
        name: 'Personal',
        keywords: this.state.description,
        patient_id: this.state.chosenPatient ? this.state.chosenPatient.id : '',
      },
    });
    this.setState({ open: false, description: '', chosenPatient: null });
    this.props.onSave();
  }

  render() {
    return (
      <Query query={getProfile}>
        {({ profileLoading, data }) => {
          if (profileLoading) return <Loader active />;
          return (
            <div className="CreateButton">
              <Mutation
                mutation={createTaskMutation}
                onError={(error) => {
                  console.error('Task creation failed:', error); // eslint-disable-line no-console
                }}
              >
                {(mutation, { loading }) => (
                  <Modal
                    size="tiny"
                    open={this.state.open}
                    className="CreateButtonModal"
                    trigger={(
                      <Button
                        basic
                        icon="plus square"
                        content="Create Task"
                        {... { loading }}
                        onClick={this.handleOpen}
                        data-cy="create-task-btn"
                      />
                    )}
                  >
                    <Modal.Header>
                      Create a Personal Task
                    </Modal.Header>
                    <Modal.Content>
                      <form id="create-task">
                        <div className="input-label">Task Description</div>
                        <Input
                          fluid
                          autoFocus
                          placeholder="Enter description"
                          value={this.state.description}
                          disabled={loading}
                          onChange={this.handleDescriptionChange}
                          data-cy="modal-task-description-input"
                        />
                        <div className="input-label">Associate with a Patient (Optional)</div>
                        <div className="patient-search-row">
                          {this.state.chosenPatient ? (
                            <Label size="medium">
                              {this.state.chosenPatient.title}
                              {' '}
                              &middot;
                              {' '}
                              {this.state.chosenPatient.display_dob}
                              <Icon name="delete" onClick={this.clearPatientSelection} />
                            </Label>
                          ) : (
                            <PatientSearchBox
                              profile={data.profile}
                              mode="search"
                              expanded
                              onResultSelect={this.handlePatientSelection}
                              disabled={this.state.disablePatientSelection}
                              data-cy="modal-patient-search-box"
                            />
                          )}
                        </div>
                      </form>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        secondary
                        type="button"
                        disabled={loading}
                        onClick={this.handleClose}
                        data-cy="modal-task-cancel-btn"
                      >
                        Cancel
                      </Button>
                      <Button
                        primary
                        type="submit"
                        form="create-task"
                        loading={loading}
                        disabled={loading || !this.state.description}
                        onClick={() => this.handleSave(mutation)}
                        data-cy="modal-task-save-btn"
                      >
                        Save
                      </Button>
                    </Modal.Actions>
                  </Modal>
                )}
              </Mutation>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default CreateButton;
