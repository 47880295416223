import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Search, Button } from 'semantic-ui-react';
import classNames from 'classnames';
import qs from 'qs';
import _ from 'lodash';
import uniqid from 'uniqid';
import Elasticsearch from './Elasticsearch';
import GooglePlaces from './GooglePlaces';
import './SearchBox.css';

const suggestQuery = ({ q, index }) => ({
  index: `${process.env.REACT_APP_ELASTICSEARCH_INDEX_PREFIX || ''}${index}`,
  body: {
    suggest: {
      autocomplete: {
        text: q,
        completion: {
          field: 'suggest',
          skip_duplicates: true,
        },
      },
    },
  },
});

class SearchBox extends Component {
  state = {
    query: '',
    skipContactSuggestions: true,
    location: '',
    skipLocationSuggestions: true,
    suggestQuery: suggestQuery({ q: '', index: this.props.index }),
  };

  componentWillMount() {
    if (window.location.search) {
      const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      this.setState({ query: params.q, location: params.loc });
    }
  }

  reload = () => {
    let destinationUrl = `/${this.props.route}?q=${encodeURIComponent(this.state.query)}`;
    if (this.state.location) destinationUrl += `&loc=${encodeURIComponent(this.state.location)}`;
    this.props.history.push(destinationUrl);
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter' && this.state.query) {
      this.querySearchRef.close();
      this.locationSearchRef.close();
      this.reload();
    }
  }

  buildQuerySuggestions = results => results.map(result => ({
    key: uniqid(),
    title: '',
    description: result,
    highlight: result.replace(
      // eslint-disable-next-line security/detect-non-literal-regexp
      new RegExp(`\\b${_.escapeRegExp(this.state.query)}`, 'gi'),
      match => `<em>${match}</em>`,
    ),
  }))

  buildLocationSuggestions = results => results.map(({ description, highlight }) => (
    { key: uniqid(), title: '', description, highlight }
  ))

  handleQueryChange = (event) => {
    this.setState({
      query: event.target.value,
      skipContactSuggestions: false,
      suggestQuery: suggestQuery({ q: event.target.value, index: this.props.index }),
    });
  };

  setSuggestQuery = (value) => {
    this.setState({ suggestQuery: suggestQuery({ q: value, index: this.props.index }) });
  }

  handleLocationChange = (event) => {
    this.setState({ location: event.target.value, skipLocationSuggestions: false });
  }

  handleQuerySuggestionSelect = (event, { result }) => {
    this.setState({ query: result.description, skipContactSuggestions: true });
  }

  handleLocationSuggestionSelect = (event, { result }) => {
    this.setState({ location: result.description, skipLocationSuggestions: true });
  }

  suggestionRenderer = ({ highlight }) => (
    <div dangerouslySetInnerHTML={{ __html: highlight }} /> // eslint-disable-line react/no-danger
  );

  render() {
    const { autoFocus, condensed } = this.props;
    return (
      <div className={classNames({ SearchBox: true, condensed })}>
        <div className="ui large fluid action input">
          <Elasticsearch
            mode="suggest"
            query={this.state.suggestQuery}
            skip={this.state.skipContactSuggestions}
            allowSimultaneousQueries
          >
            {({ results, searching }) => (
              <Search
                ref={(ref) => { this.querySearchRef = ref; }}
                input={{
                  label: 'Find',
                  labelPosition: 'left',
                  fluid: true,
                  autoFocus,
                }}
                fluid
                loading={searching}
                placeholder={this.props.placeholder}
                value={this.state.query}
                showNoResults={false}
                onSearchChange={_.throttle(this.handleQueryChange, 500, { leading: true })}
                results={this.buildQuerySuggestions(results)}
                onResultSelect={this.handleQuerySuggestionSelect}
                onKeyPress={this.handleKeyPress}
                resultRenderer={this.suggestionRenderer}
                data-cy="search-box-left-input"
              />
            )}
          </Elasticsearch>
          <GooglePlaces input={this.state.location} skip={this.state.skipLocationSuggestions}>
            {({ results, searching }) => (
              <Search
                ref={(ref) => { this.locationSearchRef = ref; }}
                input={{
                  label: 'Near',
                  labelPosition: 'left',
                  fluid: true,
                }}
                fluid
                loading={searching}
                placeholder="address, zip, place..."
                value={this.state.location}
                showNoResults={false}
                onSearchChange={_.debounce(this.handleLocationChange, 500, { leading: true })}
                results={this.buildLocationSuggestions(results)}
                onResultSelect={this.handleLocationSuggestionSelect}
                onKeyPress={this.handleKeyPress}
                resultRenderer={this.suggestionRenderer}
                data-cy="search-box-location-input"
              />
            )}
          </GooglePlaces>
          <Button
            type="button"
            primary
            icon="search"
            onClick={this.reload}
            disabled={!this.state.query}
            data-cy="search-box-button"
          />
        </div>
      </div>
    );
  }
}

SearchBox.propTypes = {
  autoFocus: PropTypes.bool,
  condensed: PropTypes.bool,
  route: PropTypes.string.isRequired,
};

export default withRouter(SearchBox);
