/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { Table, Message } from 'semantic-ui-react';
import _ from 'lodash';
import './InheritanceDiffTable.css';

const emptyValue = (value) => {
  if (value == null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0)) {
    return true;
  }
  return false;
};

class InheritanceDiffTable extends Component {
  state = { tableContainsDiff: false };

  generateAddressCell = address => (
    <>
      <p>{address.address1}</p>
      <p>{address.address2}</p>
      <p>{`${address.city}, ${address.state.short_name ? address.state.short_name : address.state} ${address.zip}`}</p>
    </>
  );

  generateInsuranceCell = (insurances, nameValue) => {
    if (!insurances) {
      return (
        <span />
      );
    }

    return <span>{insurances.map(insurance => insurance[nameValue]).join(', ')}</span>;
  }

  generateHealthSystemPartnershipInfoDiff = () => {
    const hspDisplayProperties = {
      is_quality: 'Recognized for Quality',
      has_preferred_access: 'Has Preferred Access',
      is_electronically_integrated: 'Electronic Integration',
    };
    const listOfDiff = [];
    Object.keys(hspDisplayProperties).forEach((property) => {
      if (this.props.existingContact[property] !== this.props.newParent[property]) {
        listOfDiff.push(property);
      }
    });

    if (listOfDiff.length) {
      if (!this.state.tableContainsDiff) {
        this.setState({ tableContainsDiff: true });
      }
      return (
        <Table.Row>
          <Table.Cell>
            <strong>Health System Partner Benefits</strong>
          </Table.Cell>
          <Table.Cell>
            <span>
              {listOfDiff.filter(HSPproperty => this.props.existingContact[HSPproperty])
                .map(HSPproperty => hspDisplayProperties[HSPproperty]).join(', ')}
            </span>
          </Table.Cell>
          <Table.Cell>
            <span>
              {listOfDiff.filter(HSPproperty => this.props.newParent[HSPproperty])
                .map(HSPproperty => hspDisplayProperties[HSPproperty]).join(', ')}
            </span>
          </Table.Cell>
        </Table.Row>
      );
    }

    return null;
  };

  hasDiff = (existingValue, newValue) => {
    let hasDiff = false;

    if (emptyValue(newValue) || (existingValue == null && newValue == null)) {
      return hasDiff;
    }

    if (existingValue == null && !emptyValue(newValue)) {
      if (this.state.tableContainsDiff === false && hasDiff) {
        this.setState({ tableContainsDiff: true });
      }
      hasDiff = true;
      return hasDiff;
    }

    if ((typeof existingValue === 'string') || (typeof existingValue === 'boolean')) {
      if (existingValue !== newValue) {
        hasDiff = true;
      }
    }

    if (Array.isArray(existingValue)) {
      if (newValue.length === 0) { return false; }
      if ((existingValue.length !== newValue.length)
      || (_.difference(existingValue, newValue).length > 0)) {
        hasDiff = true;
      }
    }

    if (typeof existingValue === 'object') {
      if (Object.keys(newValue).length === 0) { return false; }
      Object.values(newValue).forEach((value) => {
        if (emptyValue(value) === false) {
          hasDiff = true;
        }
      });
    }
    if (this.state.tableContainsDiff === false && hasDiff) {
      this.setState({ tableContainsDiff: hasDiff });
    }
    return hasDiff;
  };

  diffBasedOnUniqueKinds = (existingContactAttr, existingParentAttr, newParentAttr = []) => {
    const inheritedKinds = newParentAttr.reduce((kinds, attr) => kinds.concat([attr.kind]), []);
    const [kindsDuplicatedInNewParent, uniqueKinds] = _.partition(existingContactAttr,
      existingAttr => inheritedKinds.includes(existingAttr.kind));

    if (!this.state.tableContainsDiff && (kindsDuplicatedInNewParent || newParentAttr.length > 0)) {
      this.setState({ tableContainsDiff: true });
    }

    return { toRemove: existingParentAttr.concat(kindsDuplicatedInNewParent), toKeep: uniqueKinds };
  };

  render() {
    const { existingContact, newParent } = this.props;
    const hasExistingParent = !!existingContact.parent;
    const existingContactWithAddress = (hasExistingParent
      && existingContact.parent.address
      && Object.keys(existingContact.parent.address).length > 0)
      ? existingContact.parent.address : existingContact;
    const existingContactAddress = {
      address1: existingContactWithAddress.address1,
      address2: existingContactWithAddress.address2,
      city: existingContactWithAddress.city,
      state: existingContactWithAddress.state,
      zip: existingContactWithAddress.zip,
    };
    const parentHas = attr => (hasExistingParent && existingContact.parent[attr] && existingContact.parent[attr].length > 0);
    const existing = attr => (parentHas(attr) ? existingContact.parent[attr] : existingContact[`${attr}_attributes`]);
    const existingInsuranceNetworks = (parentHas('insurance_networks')
      ? existingContact.parent.insurance_networks : existingContact.insurance_networks);
    const existingInsuranceNotes = (parentHas('insurance_notes')
      ? existingContact.parent.insurance_notes : existingContact.insurance_notes);

    const { toRemove: phonesToRemove, toKeep: phonesToKeep } = this.diffBasedOnUniqueKinds(
      existingContact.phone_numbers_attributes,
      parentHas('phone_numbers') ? existingContact.parent.phone_numbers : [],
      newParent.phone_numbers,
    );
    const { toRemove: emailsToRemove, toKeep: emailsToKeep } = this.diffBasedOnUniqueKinds(
      existingContact.emails_attributes,
      parentHas('emails') ? existingContact.parent.emails : [],
      newParent.emails,
    );
    return (
      <>
        {this.state.tableContainsDiff && (
          <Message color="yellow">
            <div>
              {`Some of this contact's info will be replaced with info fetched from ${newParent.display_name}.`}
            </div>
            <div>Going forward these fields can only be updated by modifying the parent contact.</div>
          </Message>
        )}
        <Table basic="very" celled collapsing className="InheritanceDiffTable">
          {this.state.tableContainsDiff && (
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3}></Table.HeaderCell>
                <Table.HeaderCell width={5}>{existingContact.display_name}</Table.HeaderCell>
                <Table.HeaderCell width={5}>{newParent.display_name}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          )}

          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <strong>Phone Numbers</strong>
              </Table.Cell>
              <Table.Cell>
                {phonesToRemove.map(phone_number => (
                  <div key={phone_number.id} className="crossed-out">
                    {`(${phone_number.number.slice(0, 3)})
                        ${phone_number.number.slice(3, 6)}-${phone_number.number.slice(6, 10)}  `}
                    {phone_number.ext && `Ext: ${phone_number.ext}`}
                    (
                    {phone_number.kind}
                    )
                  </div>
                ))}
                {phonesToKeep.map(phone_number => (
                  <div key={phone_number.id}>
                    {`(${phone_number.number.slice(0, 3)})
                        ${phone_number.number.slice(3, 6)}-${phone_number.number.slice(6, 10)}  `}
                    {phone_number.ext && `Ext: ${phone_number.ext}`}
                    (
                    {phone_number.kind}
                    )
                  </div>
                ))}
              </Table.Cell>
              <Table.Cell>
                {(newParent.phone_numbers || []).map(phone_number => (
                  <div key={phone_number.id}>
                    {`(${phone_number.number.slice(0, 3)})
                        ${phone_number.number.slice(3, 6)}-${phone_number.number.slice(6, 10)}  `}
                    {phone_number.ext && `Ext: ${phone_number.ext}`}
                    (
                    {phone_number.kind}
                    )
                  </div>
                ))}
              </Table.Cell>
            </Table.Row>
            {this.hasDiff(existingContact.no_physical_location, newParent.no_physical_location) && (
              <Table.Row>
                <Table.Cell>
                  <strong>No Physical Location?</strong>
                </Table.Cell>
                <Table.Cell>{existingContact.no_physical_location ? 'Yes' : 'No'}</Table.Cell>
                <Table.Cell>{newParent.no_physical_location ? 'Yes' : 'No'}</Table.Cell>
              </Table.Row>
            )}
            {this.hasDiff(existingContactAddress, newParent.address) && (
              <Table.Row>
                <Table.Cell>
                  <strong>Address</strong>
                </Table.Cell>
                <Table.Cell>
                  {existingContactAddress.address1 && (this.generateAddressCell(existingContactAddress))}
                </Table.Cell>
                <Table.Cell>
                  {newParent.address && (this.generateAddressCell(newParent.address))}
                </Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell>
                <strong>Emails</strong>
              </Table.Cell>
              <Table.Cell>
                {emailsToRemove.map(email => (
                  <div key={email.id} className="crossed-out">
                    {`${email.email} `}
                    (
                    {email.kind}
                    )
                  </div>
                ))}
                {emailsToKeep.map(email => (
                  <div key={email.id}>
                    {`${email.email} `}
                    (
                    {email.kind}
                    )
                  </div>
                ))}
              </Table.Cell>
              <Table.Cell>
                {(newParent.emails || []).map(email => (
                  <div key={email.id}>
                    {`${email.email} `}
                    (
                    {email.kind}
                    )
                  </div>
                ))}
              </Table.Cell>
            </Table.Row>
            {this.hasDiff(existing('contact_insurances'), newParent.contact_insurances) && (
              <Table.Row>
                <Table.Cell>
                  <strong>Accepted Insurances</strong>
                </Table.Cell>
                <Table.Cell>
                  {this.generateInsuranceCell(existing('contact_insurances'), 'insurance_carrier_name')}
                </Table.Cell>
                <Table.Cell>
                  {this.generateInsuranceCell(newParent.contact_insurances, 'insurance_carrier_name')}
                </Table.Cell>
              </Table.Row>
            )}
            {this.hasDiff(existing('non_accepted_contact_insurances'), newParent.non_accepted_contact_insurances) && (
              <Table.Row>
                <Table.Cell>
                  <strong>Non-Accepted Insurances</strong>
                </Table.Cell>
                <Table.Cell>
                  {this.generateInsuranceCell(existing('non_accepted_contact_insurances'), 'insurance_carrier_name')}
                </Table.Cell>
                <Table.Cell>
                  {this.generateInsuranceCell(newParent.non_accepted_contact_insurances, 'insurance_carrier_name')}
                </Table.Cell>
              </Table.Row>
            )}
            {this.hasDiff(existingInsuranceNetworks, newParent.insurance_networks) && (
              <Table.Row>
                <Table.Cell>
                  <strong>Accepted Networks</strong>
                </Table.Cell>
                <Table.Cell>
                  {this.generateInsuranceCell(existingInsuranceNetworks, 'name')}
                </Table.Cell>
                <Table.Cell>
                  {this.generateInsuranceCell(newParent.insurance_networks, 'name')}
                </Table.Cell>
              </Table.Row>
            )}
            {this.hasDiff(existingInsuranceNotes, newParent.insurance_notes) && (
              <Table.Row>
                <Table.Cell>
                  <strong>Insurance Notes</strong>
                </Table.Cell>
                <Table.Cell>
                  <>{existingInsuranceNotes}</>
                </Table.Cell>
                <Table.Cell>
                  <>{newParent.insurance_notes}</>
                </Table.Cell>
              </Table.Row>
            )}
            {this.hasDiff(existingContact.is_partner, newParent.is_partner) && (
              <Table.Row>
                <Table.Cell>
                  <strong>Is a Health System Partner?</strong>
                </Table.Cell>
                <Table.Cell>{existingContact.is_partner ? 'Yes' : 'No'}</Table.Cell>
                <Table.Cell>{newParent.is_partner ? 'Yes' : 'No'}</Table.Cell>
              </Table.Row>
            )}
            {this.generateHealthSystemPartnershipInfoDiff()}
          </Table.Body>
        </Table>
      </>
    );
  }
}

export default InheritanceDiffTable;
