import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Button, Dropdown, Label, Modal, Header } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import Case from 'case';
import mixpanel from '../mixpanel';
import 'react-datepicker/dist/react-datepicker.css';
import './StateButton.css';

const triggerTaskMutation = gql`
  mutation($id: ID!, $event: String, $defer_until: DateTime) {
    trigger_task(input: { id: $id, event: $event, defer_until: $defer_until }) {
      success
      errors
      task { id, target_url, state, state_transitioned_at, next_actions }
    }
  }
`;

class StateButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task: props.task,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps !== this.props) {
      this.setState({ task: newProps.task });
    }
  }

  triggerTask = (mutation, action, params) => {
    mutation({ variables: { ...{ id: this.state.task.id, event: action.event }, ...params } })
      .then(({ data: { trigger_task: { task } } }) => {
        this.setState({ task });
        if (action.open_target) window.open(task.target_url, '_blank');
      });
  }

  render() {
    const { task } = this.state;
    const { bucket } = this.props.match.params;

    return (
      <div className="StateButton">
        <Mutation
          mutation={triggerTaskMutation}
          onError={(error) => { console.error('Task triggering failed:', error); }} // eslint-disable-line no-console
        >
          {(mutation, { loading }) => {
            if (task && task.state === 'finished') return <Label size="large">Finished</Label>;
            return (
              <>
                <Button.Group size="tiny">
                  <Button
                    {... { loading }}
                    content={task.next_actions.primary.description}
                    onClick={() => {
                      mixpanel.track('Task Status Updated', {
                        workflow: 'Tasking',
                        component: Case.title(bucket),
                        subcomponent: `${task.next_actions.primary.description} Button`,
                        object_id: task.id,
                      });
                      this.triggerTask(mutation, task.next_actions.primary);
                    }}
                    data-cy="tasks-action-btn"
                  />
                  { task.next_actions.secondary && (
                    <Dropdown button>
                      <Dropdown.Menu>
                        { task.next_actions.secondary.map(action => (
                          action.event === 'defer' ? (
                            <Modal
                              className="TasksModal"
                              key={action.description}
                              trigger={<Dropdown.Item content={action.description} />}
                              size="large"
                              basic
                            >
                              <Header>Defer Task Until</Header>
                              <Modal.Content>
                                <DatePicker
                                  calendarClassName="modal-datepicker"
                                  inline
                                  minDate={new Date()}
                                  onChange={date => this.triggerTask(mutation, action, { defer_until: date })}
                                  monthsShown={3}
                                  inlineFocusSelectedMonth
                                />
                              </Modal.Content>
                            </Modal>
                          ) : (
                            <Dropdown.Item
                              key={action.description}
                              onClick={() => {
                                mixpanel.track('Task Status Updated', {
                                  workflow: 'Tasking',
                                  component: Case.title(bucket),
                                  subcomponent: `${action.description} Button`,
                                  object_id: task.id,
                                });
                                this.triggerTask(mutation, action);
                              }}
                            >
                              {action.description}
                            </Dropdown.Item>
                          )
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </Button.Group>
              </>
            );
          }}
        </Mutation>
      </div>
    );
  }
}

export default withRouter(StateButton);
