import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Dropdown } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSlash, faHeadSideBrain } from '@fortawesome/pro-regular-svg-icons';
import { logout } from '../auth';
import roles from '../data/roles';

const updateProfileMutation = gql`
  mutation($role_ids: [ID!]!) {
    update_profile(input: { role_ids: $role_ids }) {
      success
      errors
      profile {
        id
        display_name
        roles {
          id
          assignable
        }
      }
    }
  }
`;

const UserMenu = ({ profile }) => {
  const currentRoleIds = profile.roles.map(r => r.id);
  const oneLifeUrl = process.env.REACT_APP_AUTH_PROVIDER_URL;

  return (
    <Dropdown
      item
      trigger={(
        <>
          {profile.display_name}
          { currentRoleIds.includes(roles.ADMIN)
            && currentRoleIds.includes(roles.FRONT_DESK_ADMIN)
            && <FontAwesomeIcon icon={faPhoneSlash} /> }
          { currentRoleIds.includes(roles.VMT)
            && currentRoleIds.includes(roles.VMT_FOCUS)
            && <FontAwesomeIcon icon={faHeadSideBrain} /> }
        </>
      )}
    >
      <Dropdown.Menu>
        <Mutation
          mutation={updateProfileMutation}
          onError={(error) => { console.error('Profile update failed:', error); }} // eslint-disable-line no-console
        >
          {mutation => (
            <>
              { currentRoleIds.includes(roles.ADMIN) && (
                currentRoleIds.includes(roles.FRONT_DESK_ADMIN) ? (
                  <Dropdown.Item onClick={() => mutation({
                    variables: { role_ids: currentRoleIds.filter(id => id !== roles.FRONT_DESK_ADMIN) },
                  })}
                  >
                    Disable Front Desk Mode
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item onClick={() => mutation({
                    variables: { role_ids: [...currentRoleIds, roles.FRONT_DESK_ADMIN] },
                  })}
                  >
                    <FontAwesomeIcon icon={faPhoneSlash} />
                    Front Desk Mode
                  </Dropdown.Item>
                )
              ) }
              { currentRoleIds.includes(roles.VMT) && (
                currentRoleIds.includes(roles.VMT_FOCUS) ? (
                  <Dropdown.Item onClick={() => mutation({
                    variables: { role_ids: currentRoleIds.filter(id => id !== roles.VMT_FOCUS) },
                  })}
                  >
                    Disable Focus Mode
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item onClick={() => mutation({
                    variables: { role_ids: [...currentRoleIds, roles.VMT_FOCUS] },
                  })}
                  >
                    <FontAwesomeIcon icon={faHeadSideBrain} />
                    Focus Mode
                  </Dropdown.Item>
                )
              ) }
            </>
          )}
        </Mutation>
        <Dropdown.Item name="my account" href={`${oneLifeUrl}/admin/my_admin_account`} target="_self">My Account</Dropdown.Item>
        <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserMenu;
