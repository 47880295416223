import React, { Component } from 'react';
import {Button, Form, Grid, Loader, Message} from 'semantic-ui-react';
import {Form as FinalForm, FormSpy} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import gql from "graphql-tag";
import {Query} from "react-apollo";
import BasicInformationSection from './BasicInformationSection';
import ContactInformationSection from './ContactInformationSection';
import EditParentWarningModal from './EditParentWarningModal';
import InsurancesSection from './InsurancesSection';
import NotesSection from './NotesSection';
import CompletionSection from './CompletionSection';
import { errorFocus } from '../components/Field';
import roles from '../data/roles';
import inheritedFields from '../data/inheritedFields';
import './ContactForm.css';

const getProfile = gql`
  query Profile {
    profile {
      id
      roles {
        id
      }
    }
  }
`;
class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editParentWarningModalOpen: false,
    };
  }

  hasAnInheritedValueChanged = (form) => {
    const dirtyFields = Object.keys(form.getState().dirtyFields);
    return dirtyFields.some((dirtyField) => {
      for (let i = 0; i < inheritedFields.length; i += 1) {
        if (dirtyField.includes(inheritedFields[i])) {
          return true;
        }
      }
      return false;
    });
  }

  handleSave = (descendants, values, form, handleSubmit) => {
    if (descendants && descendants.length
      && !this.state.editParentWarningModalOpen && this.hasAnInheritedValueChanged(form)) {
      this.setState({ editParentWarningModalOpen: true });
      return;
    }
    handleSubmit(values);
  }

  render() {
    const { initialValues, onSubmit, onCancel, contactKind, descendants } = { ...this.props };
    return (
      <Query query={getProfile}>
        {({ loading, data: { profile } }) => {
          if (loading) return <Loader active />;

          const isContactAdmin = profile.roles.map(role => role.id).includes(roles.CONTACT_ADMIN);
          return (
            <FinalForm
              mutators={{ ...arrayMutators }}
              initialValues={initialValues}
              onSubmit={values => onSubmit(values)}
              decorators={[errorFocus]}
            >
              {({
                handleSubmit,
                submitting,
                submitFailed,
                submitErrors,
                pristine,
              }) => (
                <Form className="ContactForm" onSubmit={handleSubmit} autoComplete="off" noValidate>
                  { submitFailed && (
                    <Grid.Row>
                      <Grid.Column>
                        { (submitErrors && submitErrors.FORM_ERROR) ? (
                          <Message
                            negative
                            header="Sorry, there was a problem saving this form"
                            content={submitErrors.FORM_ERROR}
                          />
                        ) : (
                          <Message
                            negative
                            header="Oops, some information still needs to be completed"
                            content="Please check the highlighted fields below"
                          />
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  { !isContactAdmin && initialValues.is_partner && (
                    <Grid.Row>
                      <Grid.Column>
                        <Message
                          negative
                          content="Only a Healthcare Systems Partnership managers can update this contact.
                                If you need to make any changes such as editing an insurance plan or adding a new specialist
                                to the contacts app, please e-mail HSPOps@onemedical.com"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  <BasicInformationSection
                    contact={initialValues}
                    contactKind={contactKind}
                    isContactAdmin={isContactAdmin}
                    descendants={descendants}
                  />
                  <ContactInformationSection contactKind={contactKind} isContactAdmin={isContactAdmin} />
                  <InsurancesSection isContactAdmin={isContactAdmin} />
                  <NotesSection contactKind={contactKind} isContactAdmin={isContactAdmin} />
                  <CompletionSection isContactAdmin={isContactAdmin} />

                  <FormSpy>
                    {({ form, values }) => (
                      <>
                        <Grid padded="vertically">
                          <Grid.Row>
                            <Grid.Column>
                              <Button
                                primary
                                type="button"
                                disabled={(values.is_partner && !isContactAdmin) || pristine || submitting}
                                loading={submitting}
                                onClick={() => this.handleSave(descendants, values, form, handleSubmit)}
                              >
                                Save
                              </Button>
                              <Button secondary type="button" disabled={submitting} onClick={onCancel}>
                                Cancel
                              </Button>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        {descendants && descendants.length > 0
                          && (
                            <EditParentWarningModal
                              descendants={descendants}
                              contactKind={contactKind}
                              submitting={submitting}
                              modalOpen={this.state.editParentWarningModalOpen}
                              onModalClose={() => this.setState({ editParentWarningModalOpen: false })}
                              confirm={() => handleSubmit(values)}
                            />
                          )}
                      </>
                    )}
                  </FormSpy>
                </Form>
              )}
            </FinalForm>
          );
        }}
      </Query>
    );
  }
}

export default ContactForm;
